import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate, StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Swipeable from 'react-swipeable'
import Transition from '../components/transition'
import BackgroundImage from '../components/background'
import { random } from '../util/number'
import './index.css'

const NEXT = [13, 32, 39, 37]

const Header = ({ name, title, date }) => (
  <header>
    <Link to="/1">{title}</Link>
    <time
      style={{
        cursor: 'pointer',
      }}
      title="The best day EVER! 😍"
    >
      {date}
    </time>
  </header>
)

const Wrapper = props => {
  const { slidesLength, data, location, children, site } = props

  useEffect(() => {
    document.addEventListener('keydown', nav)
    return () => document.removeEventListener('keydown', nav)
  }, [location.pathname])

  const nav = ({ keyCode }) => {
    let now = data.slide.index
    if (now) {
      if (NEXT.indexOf(keyCode) !== -1) {
        navigate(`/${random(1, slidesLength)}`)
      }
    }
  }

  const swipeLeft = () => nav({ keyCode: NEXT[0] })
  const swipeRight = () => nav({ keyCode: NEXT[3] })

  return (
    <BackgroundImage>
      <Helmet title={`Schwee 💙 Schwanna`} />
      <Header
        name={site.siteMetadata.name}
        title={site.siteMetadata.title}
        date={site.siteMetadata.date}
      />
      <Swipeable onSwipedLeft={swipeLeft} onSwipedRight={swipeRight}>
        <Transition location={location}>
          <div id="slide" style={{ width: '100%' }}>
            {children}
          </div>
        </Transition>
      </Swipeable>
    </BackgroundImage>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            name
            title
            date
          }
        }
        allSlide {
          edges {
            node {
              id
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper
        site={data.site}
        slidesLength={data.allSlide.edges.length}
        {...props}
      />
    )}
  />
)
