import React, { useState, Fragment, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { random } from '../util/number'

const Background = ({ children, className }) => {
  const [images, setImages] = useState(null)

  const [opacity, setOpacity] = useState(1)
  useEffect(() => {
    setTimeout(() => setOpacity(0.7), 2000)
    return () => setOpacity(1)
  }, [])

  return (
    <Fragment>
      {images ? (
        <BackgroundImage
          backgroundColor={'#000'}
          id={className}
          fluid={images[random(0, images.length)].node.fluid}
          Tag="section"
          style={{
            opacity,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            minHeight: '100vh',
            width: '100%',
          }}
        >
          <div>{children}</div>
        </BackgroundImage>
      ) : (
        <StaticQuery
          query={graphql`
            query {
              allImageSharp {
                edges {
                  node {
                    fluid(maxWidth: 1920, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            const { edges } = data.allImageSharp
            setImages(edges)
            const index = random(0, edges.length)
            const fluid = edges[index].node.fluid
            return (
              <BackgroundImage
                backgroundColor={'#000'}
                id={className}
                fluid={fluid}
                Tag="section"
                style={{
                  opacity,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  minHeight: '100vh',
                  width: '100%',
                }}
              >
                <div>{children}</div>
              </BackgroundImage>
            )
          }}
        />
      )}
    </Fragment>
  )
}

export default Background
